const ProductTypeHelper = (source) => {
    // Go from leafs to root
    let product_name = (source.name || '').trim().toLowerCase();
    if ((source.sku || 'X')[0] === 'W') {
        if (product_name.indexOf("ladies") !== -1) return "Ladies Watch";
        if ((source.categories || []).filter(cat => cat.name.toLowerCase().indexOf("pocket watch") !== -1).length > 0)
            return "Pocket Watch";
        return "Mens Watch";
    }
    for (let cat of (source.categories || []).sort((a, b) => (b.level || 0) - (a.level || 0))) {
        let category_name = (cat.name || '').trim().toLowerCase();
        if (category_name.indexOf('sterling silver') !== -1) return "Sterling Silver";
        if (category_name.indexOf('watch band') !== -1) {
            if (product_name.indexOf('strap') !== -1) return "Watch Strap";
            return "Watch Band";
        }
        if (category_name.indexOf('pocket watch') !== -1) return "Pocket Watch";
        if (category_name.indexOf('gia diamond') !== -1) return "GIA Diamond";
        if (category_name.indexOf('sterling silver') !== -1) return "Sterling Silver";
        if (category_name.indexOf('earring') !== -1) return "Earring";
        if (category_name.indexOf('pendant') !== -1) return "Necklace";
        if (category_name.indexOf('necklace') !== -1) return "Necklace";
        if (category_name.indexOf('bracelet') !== -1) return "Bracelet";
        if (category_name.indexOf('accessories') !== -1) return "Accessory";
        if (category_name.indexOf('pens') !== -1) return "Pen";
        if (category_name.indexOf('glasses') !== -1) return "Glasses";
        if (category_name.indexOf('dials') !== -1) return "Dial";
        if (category_name.indexOf('watchwinders') !== -1) return "Watchwinder";
        if (category_name.indexOf('ring') !== -1) return "Ring";
    }
    return "Jewelry";
}

exports.ProductTypeHelper = ProductTypeHelper